import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {emailPattern} from "../../../../../functions/patterns";
import CiviliteSelection from "../../../../personne/CiviliteSelection/CiviliteSelection";
import React, {useEffect, useState} from "react";

function AccompagnantEditForm({ setValueInitial, getValues, setShowEditModal, index, accompagnant, dataInvitation = null, getTabPaiementsSurPlaceActif, getTabPaiementsCBEnAttenteActif }) {

    const { register, control, setValue, handleSubmit,
        formState: { errors } } = useForm({defaultValues: accompagnant});
    const [isPaiementCBSelected, setIsPaiementCBSelected] = useState(true);

    useEffect(() => {
        let tmp = (getValues("tabAccompagnants."+index))
        setIsPaiementCBSelected(tmp?.tabPaiements[2].actif)
    }, [setShowEditModal]);

    function onSave(data){
        // let tmp = (getValues("tabAccompagnants."+index).find(obj => obj.destinataire.personne.id == accompagnant.destinataire.personne.id))
        let tmp = (getValues("tabAccompagnants."+index))
        tmp.destinataire.personne.prenom = data.destinataire.personne.prenom;
        tmp.destinataire.personne.nom = data.destinataire.personne.nom;
        tmp.destinataire.personne.email = data.destinataire.personne.email;
        tmp.destinataire.personne.civilite = data.destinataire.personne.civilite;
        tmp.tabPaiements = data.tabPaiements
        setValueInitial("tabAccompagnants."+index, tmp)
        setShowEditModal(false)
    }

    return <div>
        <Row>
            <Form.Group className={'mb-3'}>
                <Form.Label className='required'>Civilité</Form.Label>
                <CiviliteSelection isSolid={false} control={control} name={`destinataire.personne.civilite`} rules={{ required: true }} />
                {errors.tabAccompagnants && errors.destinataire?.personne?.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Form.Group>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Prenom</Form.Label>
                    <Form.Control maxLength={20} className='form-control' {...register(`destinataire.personne.prenom`, {required : true})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control maxLength={50} className='form-control' {...register(`destinataire.personne.nom`, {required : true})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control maxLength={50} className='form-control' {...register(`destinataire.personne.email`, {required : true, pattern: emailPattern()})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                </FormGroup>
            </Col>
            {((dataInvitation?.paiementEnLigneIsUtilise && dataInvitation?.paiementSurPlaceIsAutorise)) &&
                <div className="d-flex flex-wrap justify-content-center mt-2">
                    <h4 className="w-100 text-center">Mode de règlement</h4>
                    <div className="btn-group" data-kt-buttons="true"
                         data-kt-buttons-target="[data-kt-button]">
                        <label
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!isPaiementCBSelected ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={() => {
                                    setValue('tabPaiements', getTabPaiementsSurPlaceActif())
                                    setIsPaiementCBSelected(false);
                                }}
                                className="btn-check" type="radio" value={1}/>
                            <span style={{fontSize: '10px'}}>{"Régler sur place"}</span></label>
                        <label
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${isPaiementCBSelected ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={() => {
                                    setValue('tabPaiements', getTabPaiementsCBEnAttenteActif())
                                    setIsPaiementCBSelected(true)
                                }}
                                className="btn-check" type="radio" value={2}/>
                            <span style={{fontSize: '10px'}}>{"Régler maintenant par carte bancaire"}</span></label>
                    </div>
                </div>
            }
        </Row>
        <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='px-20'
                onClick={handleSubmit(onSave)}
            >Modifier l'accompagnant</Button>
        </div>
    </div>;
}

export default AccompagnantEditForm;