import { proxy } from "valtio";

const facturationProxy = proxy({
    showExportFacturationModal: false,
    showFiltreParInvitation: false,
    showTelechargementFacturationModal: false,
    currentFilterUrl:null,
    showDetailReversionModal:false,
    reversion : null,
    showExportFacturation:(currentFilterUrl) => {
        facturationProxy.showExportFacturationModal = true;
        facturationProxy.currentFilterUrl = currentFilterUrl;
    },
    showTelechargementFacturation:() => {
        facturationProxy.showExportFacturationModal = false;
        facturationProxy.showTelechargementFacturationModal = true;
    },
    showDetailReversion:(reversion) => {
        facturationProxy.showExportFacturationModal = false;
        facturationProxy.showTelechargementFacturationModal = false;
        facturationProxy.showTelechargementFacturationModal = false;

        facturationProxy.showDetailReversionModal = true;
        facturationProxy.reversion = reversion;
    }
});

export default facturationProxy;