import {useParams} from "react-router-dom";
export default function RedirectAppMobile(){
    const {agence} = useParams();
    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod'){
            return "iOS"
        }
        return "Other"
    }

    if (getMobileOS() == "Android" || getMobileOS() == "Other"){
        if (agence == "ligue_occitanie_rugby"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.ligueoccitanierugby.app"
        }else if (agence == "tmb"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.app.tmb"

        }else if (agence == "blagnac_rugby"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.bscr.app"

        }else if (agence == "sporting_club_albigeois"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.sportingclubalbigeois.app"
        }else if (agence == "bcc"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.businessclubcatalan.app"
        }else if (agence == "sports_region_club_50"){
            document.location.href = "https://play.google.com/store/apps/details?id=fr.sportsregionleclub.app"
        }
    }else {
        if (agence == "ligue_occitanie_rugby"){
            document.location.href = "https://apps.apple.com/us/app/ligue-occitanie-rugby/id6471621595"
        }else if (agence == "tmb"){
            document.location.href = "https://apps.apple.com/us/app/toulouse-m%C3%A9tropole-basket/id1270158273"

        }else if (agence == "blagnac_rugby"){
            document.location.href = "https://apps.apple.com/us/app/blagnac-rugby-business/id966215054"

        }else if (agence == "sporting_club_albigeois"){
            document.location.href = "https://apps.apple.com/us/app/sca-club-affaires/id6532598586"
        }else if (agence == "bcc"){
            document.location.href = "https://apps.apple.com/us/app/business-club-catalan/id6739587354"
        }else if (agence == "sports_region_club_50"){
            document.location.href = "https://apps.apple.com/us/app/sports-r%C3%A9gion-le-club/id6742135067"
        }
    }

}