import {Button, Col, Form, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import publicationMobileProxy from "../../../../../proxies/publicationMobile";
import FormDatePicker from "../../../../../components/form/FormDatePicker/FormDatePicker";
import useChangePublication from "../../../../../requests/communication/publications/useChangePublication";
import CropperForPublicationModal from "../../CropperForPublicationModal/CropperForPublicationModal";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";
import $ from "jquery";
import fileManagerProxy from "../../../../../proxies/fileManager";
import useUploadFile from "../../../../../requests/upload/useUploadFile";
import GrapesJsForPublicationModal from "../../GrapesJsForPublicationModal/GrapesJsForPublicationModal";
import SelectTemplateModal from "../../GrapesJsForPublicationModal/SelectTemplateModal";
import moment from "moment/moment";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";

const ReglagesStep = forwardRef((_, ref) => {
    // const changeSms = useChangeSms();
    const changePublication = useChangePublication();
    const fileManagerSnap = useSnapshot(fileManagerProxy);
    const publicationMobileSnap = useSnapshot(publicationMobileProxy);
    const [lienOrPageWeb, setLienOrPageWeb] = useState(publicationMobileSnap.publicationMobile?.contenu ? "page" : "lien");
    const uploadFile = useUploadFile({
        type: 'userfile',
        onSuccess: (data) => {
            setValue("lien", data?.urlOriginale)
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        },
    });
    const {
        register, handleSubmit, unregister, reset, control, setValue, getValues, watch,
        formState: { errors },
    } = useForm({
        defaultValues: publicationMobileProxy.publicationMobile,
    });
    useEffect(() => {
        reset(publicationMobileSnap.publicationMobile ?? {
            id: null,
        });
    }, [reset, publicationMobileSnap.publicationMobile]);
    const btnIsVisible = watch("boutonIsVisible")
    const titreIsVisible = watch("titreIsVisible")
    const accrocheIsVisible = watch("accrocheIsVisible")

    useEffect(() => {
        if (fileManagerSnap.urlForPublicationMobile && !fileManagerSnap.initUrlForPublicationMobile){
            setValue("lien", fileManagerSnap.urlForPublicationMobile);
            fileManagerProxy.initUrlForPublicationMobile = true
        }
    }, [fileManagerSnap.urlForPublicationMobile])

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                try {
                    if (data?.upload?.urlOriginale){
                        if (lienOrPageWeb === "page" && !data?.contenu){
                            showErrorAlert("Contenu manquant", "Merci de renseigner le contenu de la page web")
                            return false;
                        }
                        if (moment(data.dateDebut, "DD/MM/YYYY") > moment(data.dateFin, "DD/MM/YYYY")){
                            showErrorAlert("Erreur dates", "La date de fin est avant la date de début")
                            return false;
                        }
                        const result = await changePublication.mutateAsync(data);
                        publicationMobileProxy.publicationMobile = result;
                        done = true;
                    }else {
                        showErrorAlert("Image manquante", "Merci de renseigner l'image")
                    }
                } catch {}
            })();
            return done;
        },
    }));

    return <div className='mw-800px m-auto'>
        <Row className='mt-5'>
            <Col lg={6} md={6} sm={12}>
                <Form.Group>
                    <Form.Label className='required'>Titre</Form.Label>
                    <Form.Control maxLength={60} {...register('titre', { required: true })} className='form-control-solid' placeholder='Titre' />
                    {errors.titre && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col>
                {
                    watch("boutonIsVisible") && <Form.Group>
                        <Form.Label className='required'>Texte du bouton</Form.Label>
                        <Form.Control maxLength={140} {...register('texteBouton', {required: true})} className='form-control-solid' placeholder='Texte du bouton' />
                        {errors.texteBouton && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                }
            </Col>
        </Row>
        <Form.Group>
            <Form.Label className='required'>Accroche</Form.Label>
            <Form.Control maxLength={140} {...register('accroche', { required: true })} className='form-control-solid' placeholder='Accroche' />
            {errors.accroche && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label className='required'>Date de début</Form.Label>
                    <FormDatePicker control={control} rules={{ required: true }} name='dateDebut' solid />
                    {errors.dateDebut && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label className='required'>Date de fin</Form.Label>
                    <FormDatePicker control={control} rules={{ required: true }} name='dateFin' solid />
                    {errors.dateFin && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Form.Group className="mt-5">
            <Form.Label className='required'>Contenu de la publication :</Form.Label>
            <div className="d-flex">
                <div className="btn-group" data-kt-buttons="true"
                     data-kt-buttons-target="[data-kt-button]">
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${lienOrPageWeb === "lien"? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                BtSwal.fire({
                                    title: `Êtes-vous sûr de vouloir renseigner un lien au lieu d'éditer une page ?`,
                                    text:"Le changement d'option entraînera la suppression des données de l'édition de page.",
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                    icon: 'warning',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        setLienOrPageWeb("lien")
                                        unregister("contenu");
                                    }
                                })
                            }}
                            className="btn-check" type="radio" name="method"/>
                        <span style={{fontSize: '10px'}}>Lien vers un document ou un site internet</span></label>
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${lienOrPageWeb === "page" ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                BtSwal.fire({
                                    title: `Êtes-vous sûr de vouloir éditer une page au lieu de renseigner un lien ?`,
                                    text:"Le changement d'option entraînera la suppression du lien renseigné.",
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                    icon: 'warning',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        setLienOrPageWeb("page");
                                        unregister("lien");
                                    }
                                })
                            }}
                            className="btn-check" type="radio" name="method" />
                        <span style={{fontSize: '10px'}}>Création d'une page internet</span></label>
                </div>
            </div>
        </Form.Group>
        {lienOrPageWeb === "lien" &&<Form.Group className="mt-5">
            <Form.Label className="required">Lien</Form.Label>
            <div className="d-flex">
                <Form.Control maxLength={2048} {...register('lien', { required: true })} className='form-control-solid' placeholder='Lien' />
                <span className="d-flex">
            <div id="custom_button"
                 onClick={() => {
                     fileManagerProxy.fileManagerProxy("", true);
                 }}
                 title={"Rechercher sur le serveur"}
                 className="d-flex align-center justify-content-center btn mb-2 p-3"
                 style={{
                     backgroundColor: "#207ab7",
                     color: "white",
                     fontWeight: "bold",
                     cursor: "pointer",
                     width: "fit-content"
                 }}>
                <img width="50px" src="/icon/serverDownload.svg"
                     style={{width: 20, padding: 0, margin: 0}}
                     alt="Rechercher sur le serveur"/>
            </div>
            <label
                title={"Rechercher sur votre pc"}
                className="btn p-3 mb-2 "
                style={{
                    backgroundColor: "#207ab7",
                    color: "white",
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    justifContent: "center",
                    alignItems: "center",
                    width: "fit-content"
                }}
                htmlFor="file1" id="custom_button_bis"
                onChange={(e) => {
                    const file = e.target.files[0];
                    uploadFile.mutate(file);
                }}
            >
                <input
                    style={{display: "none"}}
                    type='file'
                    id='file1'
                />
                <img width="50px" src="/icon/computerUpload.svg"
                     style={{width: 20, padding: 0, margin: 0}}
                     alt="Rechercher sur votre pc"/>
            </label>
        </span>
            </div>
            {errors.lien && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>}
        <div className="d-flex flex-wrap">
            <Form.Label className="mt-5 required w-100">Visibilité</Form.Label>
            <Form.Check
                type='switch'
                className='mt-3'
                // disabled={true}
                label='Bouton visibe'
                {...register('boutonIsVisible')}
            />
            <Form.Check
                type='switch'
                className='ms-5 mt-3'
                // disabled={true}
                label='Titre visible'
                {...register('titreIsVisible')}
            />
            <Form.Check
                type='switch'
                className='ms-5 mt-3'
                // disabled={true}
                label='Accroche visible'
                {...register('accrocheIsVisible')}
            />
        </div>
        {lienOrPageWeb === "page" &&
            <div>
                <Form.Control {...register('contenu')}  type={"hidden"}/>
                <Button
                    className="ms-5 mt-5"
                    style={{height: "fit-content"}}
                    onClick={() => {
                        if (!publicationMobileSnap?.publicationMobile?.contenu && !getValues("contenu")) {
                            publicationMobileProxy.showSelectTemplateGrapesForPublication();
                        }else {
                            publicationMobileProxy.showEditGrapesForPublication();
                        }
                    }}>
                    Editer le contenu
                </Button>
            </div>
        }
        <Form.Group className="mt-5">
            <Form.Label className='required'>Image</Form.Label><br/><br/>
            <div className="d-flex position-relative">
                <img src="/iphone-14-pro-max.png" width={360} style={{minWidth:360}} height={313} alt=""/>
                <div className="position-relative" style={{zIndex:1, left: "-258px", top:"6px"}}>
                    <div id="gradienImgIphone" className="position-absolute" style={{width:155, height:290, borderRadius: "20px"}}></div>
                    <div id="cropper-preview-lg" className="mb-3 position-relative"
                         style={{backgroundColor: "#f7f7f7", zIndex:1, height:260, borderRadius: "20px"}}>
                        {getValues("upload.urlOriginale") &&<img width={154.67} height={290} style={{borderRadius: "20px"}} src={getValues("upload.urlOriginale")} alt="image"/>}
                    </div>
                    <div className="position-absolute"
                         style={{bottom: "7%", lineHeight: "10px", padding: "10px", width: "100%", zIndex: 2}}>
                        {
                            titreIsVisible && <span style={{
                                lineHeight: "8px",
                                fontSize: "14px",
                                fontFamily: "CRACKERS"
                            }}>{watch("titre")}</span>
                        }
                        <br/>
                        <div style={{height: 5}}></div>
                        {
                            accrocheIsVisible && <span style={{
                                lineHeight: "6px",
                                fontSize: "6.5px",
                                fontFamily: "'Source Sans Pro', sans-serif"
                            }}>{watch("accroche")}</span>
                        }

                        <div style={{height: 5}}></div>
                        {
                            btnIsVisible && <Button
                                className="mt-1"
                                style={{padding: "0", borderRadius: "4px", width: "100%", height: "23px", fontSize: "8px"}}
                                variant={"secondary"}
                            >
                                {watch("texteBouton")}
                            </Button>
                        }

                    </div>
                    <div>
                    </div>
                </div>
                <Button
                    className="ms-5 position-absolute"
                    style={{height: "fit-content", left:300, top:100}}
                    onClick={() => {
                        publicationMobileProxy.showEditCropperForPublication();
                    }}>
                    {getValues('upload.urlOriginale') ? "Modifier l'image" : "Importer l'image"}
                </Button>
            </div>
        </Form.Group>
        <CropperForPublicationModal
            getValues={getValues}
            onChangeForPublication={setValue}
        />
        <SelectTemplateModal/>
        <GrapesJsForPublicationModal
            getValues={getValues}
            onChangeForPublication={setValue}
        />
    </div>;
});

export default ReglagesStep;