import {useQuery} from "react-query";
import api from "@services/api";

export default function useGetIdsInvitationsReversion(id) {

    if (id){
        return useQuery(
            ['idsReversion', id],
            async () => {
                return api.get(`reversionPaiement/${id}/idsInvitation`).json();
            }
        );
    }

}