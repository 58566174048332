import React from "react";

export function renderSwitchInscriptionInscriptionLibreEtPreview(champsPerso, key, color, texteComplementaire = "") {
    if (champsPerso && champsPerso[key] && champsPerso[key].state.active) {
        switch (key) {
            case "titre1" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "titre2" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "sousTexteTitre2" :
                return <p className="text-white" dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html.replace("<p>", "<p>"+texteComplementaire)
                }}>
                </p>;
            case "titreDecline" :
                return <span dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}>
                </span>;
            case "texteDecline" :
                return <div dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }} className='mt-5 mx-10'></div>;
            case "champs1":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs2":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs3":
                return <div className="position-relative pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "champs4":
                return <div className="position-relative p-10 pb-5">
                    <div style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></div>
                </div>;
            case "default":
                return "";
        }
    }
}

export function getResultByKeyWord({invitation, personne, keyWord}){
    switch (keyWord){
        case "{civiliteInvite}" :
            return personne?.civilite;
        case "{nomInvite}" :
            return personne?.nom;
        case "{prenomInvite}" :
            return personne?.prenom;
        case "{emailInvite}" :
            return personne?.email;
        case "{vouvoiement}" :
            return "vous";
        case "{vouvoiementPronoms}" :
            return "votre";
        case "{vouvoiementPronomsFeminin}" :
            return "votre";
        case "{vouvoiementPronomsMasculin}" :
            return "votre";
        case "{vouvoiementPronomsPluriel}" :
            return "vos";
        case "{preludeAdresseEvenement}" :
            return invitation?.preludeAdresse;
        case "{adresseEvenement}" :
            return invitation?.adresse;
        case "{codePostalEvenement}" :
            return invitation?.codePostal;
        case "{villeEvenement}" :
            return invitation?.ville;
        case "{dateEvenement}" :
            return invitation?.dateEvenementFormatee;
        case "{typeEvenement}" :
            return invitation?.type?.motDeLiaison+invitation?.type?.libelle;
        case "{theme}" :
            return invitation?.theme;
        case "{sousTheme}" :
            return invitation?.sousTheme;
        default : return null
    }
}

export function searchMotsCleAndReplace(content, invitation, personne){
    let reg = [];
    let searchStart = true;
    let indexStartFind = 0;
    let searchStop = false;
    let indexStopFind = 0;
    for (let i = 0; i < content.length; i++) {
        if (content[i] === '{' && searchStart){
            searchStart = false;
            searchStop = true;
            indexStartFind = i;
        }else if (content[i] === '}' && searchStop){
            searchStart = true;
            searchStop = false;
            indexStopFind = i;
            reg.push(content.substring(indexStartFind, indexStopFind+1))
        }
    }
    let text = content;
    if (reg){
        reg.forEach((item) => {
            if (getResultByKeyWord({invitation, personne, keyWord:item, montant:1000})){
                text = text.replace(item, getResultByKeyWord({invitation, personne, keyWord:item}))
            }else {
                text = text.replace(item, "");
            }
        })
    }

    return text;
}