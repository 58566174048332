import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetExportFacturation(idInvitation = null, isInclusif = true) {


    return useQuery(
        ["urlExportFacture", idInvitation, isInclusif],
        async () => {
            //faire un filtre reversion sur une invitation donnée ( en attente route back )
            //avoir un listing des invitations lié à une facture ( que l'invitation soit supprimé ou non )
            if (idInvitation){
                return api.get("facture/urlExport&idInvitation="+idInvitation+"&isInclusif="+isInclusif).json();
            }else {
                return api.get("facture/urlExport").json();
            }
        }
    );
}