import { ReactComponent as Logo } from "@assets/logo.svg";
import SendResetPasswordForm from "./SendResetPasswordForm";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import {useParams} from "react-router-dom";
import {useLocation} from "react-router";

function SuccessView() {
    const snapAuth = useSnapshot(auth);
    const location = useLocation();
    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Félicitations</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
            <div className='d-flex flex-center flex-column flex-column-fluid'>
                <div className='p-lg-15 mx-auto'>
                    <h1 className="text-center">{location?.state?.titleText ? location?.state?.titleText : "Félicitations"}</h1>
                    <img style={{maxHeight:"80vh"}} className="img img-fluid" src="/icon/9812.jpg" alt="Image de succès"/>
                </div>
            </div>
        </div>
    </div>;
}

export default SuccessView;
