import React, {useEffect, useMemo} from "react";
import {useSnapshot} from "valtio";
import invitationsProxy from "../../../../../proxies/invitations";
import auth from "../../../../../services/auth";
import {
    searchMotsCleAndReplace
} from "../../../../../views/communication/InvitationsView/functions";

export default function PreviewFacturationModal({valeurPersonalise}){
    const snapAuth = useSnapshot(auth);
    const invitationSnap = useSnapshot(invitationsProxy)
    const designationFixe = useMemo(() => {
        if (invitationSnap?.invitation?.tabOptionsParticipation){
            let tabOptionParticipation = Object.values(invitationSnap?.invitation?.tabOptionsParticipation)
            let tabArticles = Object?.values(tabOptionParticipation[0]?.tabArticles)
            return <span><span className="bg-success">{tabOptionParticipation[0]?.designation}</span> - <span>{tabArticles[0].designation}</span></span>
        }else {
            return null
        }
    }, [invitationSnap?.invitation?.id])
    return <>
        <table className="mt-3 mb-5" align={"center"} width={710}>
            <thead style={{
                backgroundColor: "var(--primary-color)",
                color: "white"
            }}>
            <tr>
                <th width={66} className="ps-2" scope="col">Ref</th>
                <th width={244} className="ps-2" scope="col">Designation</th>
                <th width={70} className="text-end pe-2" scope="col">Qté</th>
                <th width={94} className="text-end pe-2" scope="col">Prix Unit. HT</th>
                <th width={96} className="text-end pe-2" scope="col">TVA %</th>
                <th width={140} className="text-end pe-2" scope="col">Total HT</th>
            </tr>
            </thead>
            <tbody>
            {designationFixe && Object.values(Object.values(invitationSnap?.invitation?.tabOptionsParticipation)[0]?.tabArticles).map((articlesPremiereOption, index) => {
                let designation = <span><span
                    className="bg-success">{Object.values(invitationSnap?.invitation?.tabOptionsParticipation)[0]?.designation}</span> - <span
                    className="bg-danger">{articlesPremiereOption.designation}</span></span>
                let designationParDefaut =
                    <span><span style={{
                        backgroundColor: "lightblue"
                    }}>{snapAuth?.utilisateur?.prenom} {snapAuth?.utilisateur?.nom}</span> - <span className="bg-gray-400">Invitation {invitationSnap?.invitation?.dateEvenementFormatee} {invitationSnap?.invitation?.type?.motDeLiaison} {invitationSnap?.invitation?.type?.libelle}</span></span>
                return <tr style={{
                    borderBottom: "1px solid var(--primary-color)"
                }}>
                    <td className="ps-2" style={{
                        verticalAlign: "top"
                    }}>kht{index}ofE
                    </td>
                    <td className="ps-2">
                        <div style={{
                            // width: 220
                        }}>
                        <span style={{
                            fontSize: 15,
                            fontFamily: "Calibri", lineHeight: "normal"
                        }}>{designation}</span><br/>
                            <span style={{
                                fontSize: 12,
                                fontStyle: "italic",
                                fontFamily: "Calibri",
                                lineHeight: "normal"
                            }}>{valeurPersonalise ?
                                <span><span style={{
                                    backgroundColor: "lightblue"
                                }}>{snapAuth?.utilisateur?.prenom} {snapAuth?.utilisateur?.nom}</span> - <span className="bg-gray-400">{searchMotsCleAndReplace(valeurPersonalise, invitationSnap?.invitation, snapAuth?.utilisateur)}</span></span> : designationParDefaut}</span>
                        </div>
                    </td>
                    <td style={{
                        verticalAlign: "top"
                    }} className="text-end pe-2">1
                    </td>
                    <td style={{
                        verticalAlign: "top"
                    }}
                        className="text-end pe-2">{(articlesPremiereOption?.montant / (1 + (articlesPremiereOption?.tauxTva?.valeur / 100))).toFixed(2)} €
                    </td>
                    <td style={{
                        verticalAlign: "top"
                    }} className="text-end pe-2">{articlesPremiereOption?.tauxTva?.valeur}
                    </td>
                    <td style={{
                        verticalAlign: "top"
                    }}
                        className="text-end pe-2">{(articlesPremiereOption?.montant / (1 + (articlesPremiereOption?.tauxTva?.valeur / 100))).toFixed(2)} €
                    </td>
                </tr>
            })}
            </tbody>
        </table>
        <span className="mt-5 w-100 d-inline">Légende : <span
            className="bg-success">Désignation de l'option de participation</span> -- <span className="bg-danger">Désignation de l'article</span> -- <span
            className="bg-gray-400">Libelle personnalisable</span> -- <span style={{
            backgroundColor: "lightblue"
        }}>Prénom NOM</span></span>
    </>;


}