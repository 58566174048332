import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Row, Stack, Button, InputGroup, Modal} from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import ArticlesOptionsForm
    from "../../../../../../views/communication/InvitationsView/EditModal/ReglagesStep/ArticlesOptionsForm/ArticlesOptionsForm";

function ParticipationOptionsForm({ control, trigger, name, register, boolPaiementEnLigneIsUtilise, getValues, unregister, errors, setValue }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });


    const [showEditOptionParticiparion, setShowEditOptionParticipation] = useState(false);
    const [indexOptionEdited, setIndexOptionEdited] = useState(0);
    const [idOptionEdited, setIdOptionEdited] = useState(0);

    return <div>
        {fields.map((item, index) => (
            <Stack key={item.id} className={index === 0 ? 'mt-5' : 'mt-5'}>
                <Row>
                    <Col md={10}>
                        <FormGroup as={Col}>
                            <Form.Label>Désignation</Form.Label>
                            <Form.Control disabled={true} maxLength={100} className="form-control-solid"
                                          style={{
                                              cursor: "not-allowed"
                                          }}
                                          {...register(`${name}.${index}.designation`)} />
                            {errors?.[name]?.[index]?.["designation"] && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col md={2} className="d-flex align-items-end">
                        <Button
                            style={{
                                height: "fit-content",
                                width: 55
                            }}
                            className='btn btn-primary me-2'
                            onClick={() => {
                                if (getValues('tabOptionsParticipation.' + index+ ".tabArticles")) {
                                    setValue('tabOptionsParticipation.' + index+ ".tabArticles", (Object?.values(getValues('tabOptionsParticipation.' + index+ ".tabArticles"))  ?? {} ))
                                }
                                setIndexOptionEdited(index)
                                setShowEditOptionParticipation(true)
                                setIdOptionEdited(item.id)
                            }}
                        >
                            <i style={{
                                color: "white"
                            }} className="fa fa-pen"></i>
                        </Button>
                        <Button
                            style={{
                                height: "fit-content",
                                width: 55
                            }}
                            className='btn btn-secondary'
                            onClick={() => remove(index)}
                        >
                            <i style={{
                                color: "white"
                            }} className="fa fa-trash"></i>
                        </Button>
                    </Col>
                </Row>
            </Stack>
        ))}
        <div className='d-flex justify-content-center mt-2'>
            <Button
                variant='link'
                className='btn-color-info btn-active-color-primary'
                onClick={() =>  {
                    append({
                        designation: '',
                        idInvitation: getValues('id'),
                        id: null,
                        tabArticles: null
                    })
                    setIndexOptionEdited(fields.length)
                    setShowEditOptionParticipation(true)
                    setIdOptionEdited(null)

                }}
            >Ajouter une option</Button>
        </div>

        <Modal
            show={showEditOptionParticiparion}
            onHide={() => setShowEditOptionParticipation(false)}
            size='lg'
            enforceFocus={false}
            keyboard={false}
            backdrop={"static"}
        >
            <Modal.Body>
                <ArticlesOptionsForm
                    setShowEditOptionParticiparion={setShowEditOptionParticipation}
                    errors={errors}
                    unregister={unregister}
                    getValues={getValues}
                    trigger={trigger}
                    boolPaiementEnLigneIsUtilise={boolPaiementEnLigneIsUtilise}
                    control={control}
                    register={register}
                    removeOption={() => {
                        setTimeout(() => remove(fields.length - 1), 500)
                    }}
                    idOption={idOptionEdited}
                    namePart1={'tabOptionsParticipation'}
                    namePart2={'tabArticles'}
                    indexOptionEdited={indexOptionEdited}
                    name={'tabOptionsParticipation.' + indexOptionEdited+ ".tabArticles"}
                    name2={'tabOptionsParticipation.' + indexOptionEdited+ ".designation"}
                />
            </Modal.Body>
        </Modal>
    </div>;
}

export default ParticipationOptionsForm;