import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import auth from "../../../../../services/auth";
import {Accordion, Alert, Button, Form} from "react-bootstrap";
import Select2 from "../../../../../components/Select2/Select2";
import {Editor} from "@tinymce/tinymce-react";
import {iconLienPj} from "../../../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../../../functions/linkUrlTinyMce";
import {useSnapshot} from "valtio";
import invitationsProxy from "../../../../../proxies/invitations";
import useUploadFile from "../../../../../requests/upload/useUploadFile";
import $ from "jquery";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";
import useChangeInvitation from "../../../../../requests/communication/invitations/useChangeInvitation";

const PersonnaliserPageReponseDestinataireStep = forwardRef((_, ref) => {
    const scrollRef = useRef(null);
    const changeInvitation = useChangeInvitation();
    const executeScroll = () => scrollRef.current.scrollIntoView()
    const [invitation, setInvitation] = useState(invitationsProxy.invitation);
    const convertTabOptionsParticipation = invitation?.tabOptionsParticipation ? Object.entries(invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const editorRef = useRef(null);
    const editorRefDecline = useRef(null);
    const editorRef2 = useRef(null);
    const editorRef3 = useRef(null);
    const editorRef4 = useRef(null);
    const editorTitre1 = useRef(null);
    const editortitreDecline = useRef(null);
    const editorTitre2 = useRef(null);
    const editorSousTexteTitre2 = useRef(null);
    const [showDecline, setShowDecline] = useState(false);
    const uploadFilePresentation = useUploadFile({ type: "userfile"});
    if (uploadFilePresentation.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFilePresentation.data.urlOriginale)
        if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }
    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }
    const [champsPerso, setChampsPerso] = useState({
        titre1: {
            html: `<h1 class="mx-10 text-center alert-info-color">Pour confirmer votre réponse, vous devez valider
                l'inscription en cliquant sur le bouton</h1>`,
            state: {
                disable: false,
                edit: false,
                active: true
            }
        },
        titreDecline : {
            html: `<h1 class="fw-bolder text-white fs-2qx pb-5 pb-md-10">Nous sommes navrés de votre choix. Peut-être à une autre fois.</h1>`,
            state: {
                disable: false,
                edit: false,
                active: true
            }
        },
        titre2: {
            html: `<h1 class="fw-bolder text-white fs-2qx pb-5 pb-md-10">Pour confirmer votre réponse, vous devez valider
                l'inscription en cliquant sur le bouton</h1>`,
            state: {
                disable: false,
                edit: false,
                active: true
            }
        },
        texteDecline: {
            html: null,
            state: {
                disable: true,
                edit: true,
                active: false,
                auto: true
            }
        },
        sousTexteTitre2: {
            html: null,
            state: {
                disable: true,
                edit: true,
                active: false,
                auto: true
            }
        },
        champs1: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs2: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs3: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs4: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        }
    })
    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [invitation?.tabOptionsParticipation]);

    useEffect(() => {
        if (champsPerso?.sousTexteTitre2?.state?.edit && champsPerso.sousTexteTitre2?.html) {
            setChampsPerso({
                ...champsPerso,
                sousTexteTitre2: {
                    ...champsPerso.sousTexteTitre2,
                    html: champsPerso.sousTexteTitre2?.html.replace("<p>Civilit&eacute; Pr&eacute;nom NOM, ", "<p>")
                }
            })
        }
    }, [champsPerso?.sousTexteTitre2?.state?.edit])
    useEffect(() => {
        setInvitation(invitationsProxy.invitation)
        if (invitationsProxy.invitation?.blocsPersonnalisesPageReponse){
            setChampsPerso(invitationsProxy.invitation.blocsPersonnalisesPageReponse)
            if (Object.values(invitationsProxy.invitation.blocsPersonnalisesPageReponse).length == 4){
                setChampsPerso({
                    ...invitationsProxy.invitation.blocsPersonnalisesPageReponse,
                    titre1: {
                        html: `<h1 class="mx-10 text-center alert-info-color">Pour confirmer votre réponse, vous devez valider
                l'inscription en cliquant sur le bouton</h1>`,
                        state: {
                            disable: false,
                            edit: false,
                            active: true
                        }
                    },
                    titreDecline : {
                        html: `<h1 class="fw-bolder text-white fs-2qx pb-5 pb-md-10">Nous sommes navrés de votre choix. Peut-être à une autre fois.</h1>`,
                        state: {
                            disable: false,
                            edit: false,
                            active: true
                        }
                    },
                    titre2: {
                        html: `<h1 class="fw-bolder text-white fs-2qx pb-5 pb-md-10">Pour confirmer votre réponse, vous devez valider
                l'inscription en cliquant sur le bouton</h1>`,
                        state: {
                            disable: false,
                            edit: false,
                            active: true
                        }
                    },
                    texteDecline: {
                        html: null,
                        state: {
                            disable: true,
                            edit: true,
                            active: false,
                            auto: true
                        }
                    },
                    sousTexteTitre2: {
                        html: null,
                        state: {
                            disable: true,
                            edit: true,
                            active: false,
                            auto: true
                        }
                    }
                })
            }
        }
        executeScroll()
    }, [invitationsSnapshot.invitation]);

    useImperativeHandle(ref, () =>
        ({
            save: async () => {
                let done = false;
                await  (async () => {
                    if (((JSON.stringify(invitationsProxy?.invitation?.blocsPersonnalisesPageReponse)) != JSON.stringify(champsPerso))) {
                        await BtSwal.fire({
                            title: 'Souhaitez-vous enregistrer toutes les modifications',
                            showDenyButton: true,
                            icon: "question",
                            confirmButtonText: `Oui`,
                            denyButtonText: `Non`,
                        }).then(async (result) => {
                            let tmpChampsPerso= champsPerso;
                            //mise à jour des états en édition
                            for (const item in tmpChampsPerso) {
                                if (tmpChampsPerso[item].state.edit && !tmpChampsPerso[item]?.state?.auto){
                                    switch (item) {
                                        case "texteDecline":
                                            tmpChampsPerso[item].html = editorRefDecline.current.getContent();
                                            break;
                                        case "titreDecline":
                                            tmpChampsPerso[item].html = editortitreDecline.current.getContent();
                                            break;
                                        case "titre1":
                                            tmpChampsPerso[item].html = editorTitre1.current.getContent();
                                            break;
                                        case "titre2":
                                            tmpChampsPerso[item].html = editorTitre2.current.getContent();
                                            break;
                                        case "sousTexteTitre2":
                                            tmpChampsPerso[item].html = editorSousTexteTitre2.current.getContent();
                                            break;
                                        case "champs1":
                                            tmpChampsPerso[item].html = editorRef.current.getContent();
                                            break;
                                        case "champs2":
                                            tmpChampsPerso[item].html = editorRef2.current.getContent();
                                            break;
                                        case "champs3":
                                            tmpChampsPerso[item].html = editorRef3.current.getContent();
                                            break;
                                        case "champs4":
                                            tmpChampsPerso[item].html = editorRef4.current.getContent();
                                            break;
                                    }
                                }
                                if (tmpChampsPerso[item].html){
                                    tmpChampsPerso[item].state.edit = false;
                                    tmpChampsPerso[item].state.active = true;
                                    tmpChampsPerso[item].state.disable = false;
                                }else {
                                    tmpChampsPerso[item].state.edit = false;
                                    tmpChampsPerso[item].state.active = false;
                                    tmpChampsPerso[item].state.disable = true;
                                }
                            }

                            //replace civilité prénom nom
                            if (tmpChampsPerso["sousTexteTitre2"]?.html){
                                tmpChampsPerso["sousTexteTitre2"].html = tmpChampsPerso["sousTexteTitre2"].html.replace("<p>Civilit&eacute; Pr&eacute;nom NOM, ", "<p>")
                            }
                            if (result.isConfirmed){
                                if (invitationsProxy.invitation){
                                    invitationsProxy.invitation.blocsPersonnalisesPageReponse = tmpChampsPerso;
                                }else {
                                    invitationsProxy.invitation = {
                                        blocsPersonnalisesPageReponse: tmpChampsPerso
                                    }
                                }
                                changeInvitation.mutate(invitationsProxy.invitation)
                                done = true
                            }else {
                                done = true
                            }
                        })
                    }else {
                        done = true
                    }
                })();
                return done;
            },
        }));

    return <div>
        <div ref={scrollRef}  className="pt-5 m-auto w-100 d-block" data-kt-buttons="true"
             data-kt-buttons-target="[data-kt-button]">
            <div className="d-flex mb-3">
                <label
                    id={"btnPAcception"}
                    className={`small p-2 btn d-block w-50 btn-secondary text-muted text-hover-white text-secondary btn-outline rounded-0 ${!showDecline ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            setShowDecline(false)
                        }}
                        className="btn-check" type="radio" name="method" />
                    <span style={{fontSize: '18px'}}>Page d'acceptation</span></label>
                <label
                    id={"btnPDeclinaison"}
                    className={`small p-2 btn btn-primary text-white d-block w-50 text-hover-white btn-outline rounded-0 ${showDecline ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            setShowDecline(true)
                        }}
                        className="btn-check" type="radio" name="method" />
                    <span style={{fontSize: '18px'}}>Page de déclinaison</span></label>
            </div>
        </div>
        {
            !showDecline ?
                <div style={{
                    minHeight: "100vh"
                }} className='d-flex flex-column flex-lg-row flex-column-fluid'>
                    <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                        <div className='d-flex flex-column top-0 bottom-0 w-xl-600px scroll-y'>
                            <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={auth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                                <>{renderSwitch("titre2", champsPerso)}
                                    {
                                        champsPerso?.sousTexteTitre2?.state?.auto ?
                                            <div className="position-relative">
                                                <div className="h-50px">
                                                    <Button
                                                        onClick={() => {
                                                            setChampsPerso({
                                                                ...champsPerso,
                                                                sousTexteTitre2: {
                                                                    ...champsPerso?.sousTexteTitre2,
                                                                    state: {
                                                                        ...champsPerso?.sousTexteTitre2?.state,
                                                                        auto: false,
                                                                        edit: true
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        style={{right: 0, top: 0, width: 35, height: 35}}
                                                        className="fa fa-pen rounded-circle position-absolute p-0"
                                                        variant={"primary"}
                                                    >
                                                    </Button>
                                                </div>
                                                <p className="text-white">Civilité Prénom NOM,
                                                    vous validez <strong>votre
                                                        présence</strong> {invitation?.type?.motDeLiaison} {invitation?.type?.libelle} du
                                                    <strong> {invitation?.dateEvenementFormatee && invitation?.dateEvenementFormatee.toLowerCase().substring(3)}</strong>.
                                                </p>
                                            </div> :
                                            <div className="position-relative">
                                                <div className="h-50px">
                                                    <Button
                                                        onClick={() => {
                                                            setChampsPerso({
                                                                ...champsPerso,
                                                                sousTexteTitre2: {
                                                                    ...champsPerso?.sousTexteTitre2,
                                                                    state: {
                                                                        ...champsPerso?.sousTexteTitre2?.state,
                                                                        auto: true
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        style={{right: 0, top: 0}}
                                                        className="position-absolute"
                                                        variant={"secondary"}
                                                    >
                                                        Par défaut
                                                    </Button>
                                                </div>
                                                {renderSwitch("sousTexteTitre2", champsPerso)}

                                            </div>
                                    }
                                </>

                                <div className=''>
                                    {renderSwitch("champs1", champsPerso)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row-fluid py-10'>
                        <div className=''>
                            <div className=''>
                                {renderSwitch("titre1", champsPerso)}
                                <div className='mt-5 mx-10 bg-white rounded'>
                                    {renderSwitch("champs2", champsPerso)}
                                </div>
                                <form>
                                    <div className=''>
                                        {((invitation?.paiementEnLigneIsUtilise && invitation?.paiementSurPlaceIsAutorise) || invitation?.champInformationComplementaireReponseIsAffiche || (invitation?.tabOptionsParticipation && Object.keys(invitation?.tabOptionsParticipation).length > 0) || invitation?.nbParticipantsMax != 0) &&
                                            <div className='mt-5 mx-10 bg-white rounded p-10 pb-4'>
                                                {invitation?.nbParticipantsMax != 0 &&
                                                    <p style={{width: "fit-content"}}
                                                       className="text-info">{invitation?.nbParticipantsMax} places
                                                        restantes.</p>}
                                                {invitation?.tabOptionsParticipation && Object.keys(invitation?.tabOptionsParticipation).length > 0 && !(invitation?.tabOptionsParticipation.length === 1 && (invitation?.tabOptionsParticipation[0].designation === "" || invitation?.tabOptionsParticipation[0].designation == null)) &&
                                                    <><Form.Group>
                                                        <Form.Label>Option de participation</Form.Label>
                                                        <Select2
                                                            disabled={true}
                                                            allowClear={true}
                                                            data={data}
                                                            onChange={(selected) => {
                                                                selected = selected?.at(0);
                                                                const res = convertTabOptionsParticipation.find(
                                                                    exp => exp.id.toString() === selected
                                                                );
                                                            }}
                                                            minimumResultsForSearch={3}
                                                            placeholder='Selectionner une option de participation'

                                                        />
                                                    </Form.Group>
                                                        <div className='mt-4'>
                                                            {renderSwitch("champs3", champsPerso)}
                                                        </div>
                                                    </>

                                                }
                                                {invitation?.champInformationComplementaireReponseIsAffiche &&
                                                    <Form.Group className='mt-5'>
                                                        <Form.Label>Information complémentaire sur votre réponse
                                                            (facultatif)
                                                            :</Form.Label>
                                                        <Form.Control
                                                            disabled={true}
                                                            as='textarea'
                                                            rows={5}
                                                        />
                                                    </Form.Group>}

                                                {invitation?.paiementEnLigneIsUtilise && invitation?.paiementSurPlaceIsAutorise &&
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <div className="btn-group" data-kt-buttons="true"
                                                             data-kt-buttons-target="[data-kt-button]">
                                                            <label
                                                                id='surPlace'
                                                                className={`${`disabled`} small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success`}
                                                                data-kt-button="true">
                                                                <input
                                                                    className="btn-check" type="radio" value={1}/>
                                                                <span>{"Régler sur place"}</span></label>
                                                            <label
                                                                id="cb"
                                                                className={`disabled small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success`}
                                                                data-kt-button="true">
                                                                <input
                                                                    className="btn-check" type="radio" value={2}/>
                                                                <span>{"Régler maintenant par carte bancaire"}</span></label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>}
                                        {(invitation?.listeCombienEtesVousAVenirIsAffiche) &&
                                            <div className='mt-5 mx-10 bg-white rounded p-10'>
                                                <Accordion disabled={true}>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            {invitation?.listeCombienEtesVousAVenirIsAffiche &&
                                                                <div
                                                                    className='mt-2 d-flex flex-wrap w-100 justify-content-between align-items-center'>
                                                                    <h2>Gestion des accompagnants <span
                                                                        className="badge badge-circle badge-primary">0</span>
                                                                    </h2>
                                                                    <Button
                                                                        disabled={true}
                                                                        variant='secondary'
                                                                        className='px-20 me-3'
                                                                    >
                                                                        Ajouter un accompagnant</Button>
                                                                </div>}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="table-responsive mt-3">
                                                                <table className="table gy-7 gs-7">
                                                                    <tbody>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>}
                                        <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
                                            <Button
                                                disabled={true}
                                                variant='primary'
                                                className='px-20'
                                            >
                                                <span>Valider l'inscription</span></Button>
                                        </div>
                                        <div className='mt-5 mx-10 bg-white rounded'>
                                            {renderSwitch("champs4", champsPerso)}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> :
                <div style={{
                    minHeight: "100vh"
                }} className='d-flex flex-column flex-lg-row flex-column-fluid'>
                    <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                        <div className='d-flex flex-column top-0 bottom-0 w-xl-600px scroll-y'>
                            <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={auth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                                <>{renderSwitch("titreDecline", champsPerso)}</>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row-fluid py-10'>
                        <div className=''>
                            <div className=''>
                                <div className='mt-5 mx-10'>
                                    {
                                        champsPerso?.texteDecline?.state?.auto ?
                                            <div className="position-relative">
                                                <div className="h-50px">
                                                    <Button
                                                        onClick={() => {
                                                            setChampsPerso({
                                                                ...champsPerso,
                                                                texteDecline: {
                                                                    ...champsPerso?.texteDecline,
                                                                    state: {
                                                                        ...champsPerso?.texteDecline?.state,
                                                                        auto: false,
                                                                        edit: true
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        style={{right: 0, top: 0, width: 35, height: 35}}
                                                        className="fa fa-pen rounded-circle position-absolute p-0"
                                                        variant={"primary"}
                                                    >
                                                    </Button>
                                                </div>
                                                <p>Merci pour votre réponse.</p>
                                                <p><strong>Vous avez décliné
                                                    l'invitation {invitation.type?.motDeLiaison} {invitation.type?.libelle}{invitation.dateEvenementFormatee.toLowerCase()}</strong>
                                                </p>
                                            </div> :
                                            <div className="position-relative">
                                                <div className="h-50px">
                                                    <Button
                                                        onClick={() => {
                                                            setChampsPerso({
                                                                ...champsPerso,
                                                                texteDecline: {
                                                                    ...champsPerso?.texteDecline,
                                                                    state: {
                                                                        ...champsPerso?.texteDecline?.state,
                                                                        auto: true
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        style={{right: 0, top: 0}}
                                                        className="position-absolute"
                                                        variant={"secondary"}
                                                    >
                                                        Par défaut
                                                    </Button>
                                                </div>
                                                {renderSwitch("texteDecline", champsPerso)}

                                            </div>
                                    }
                                    <div id="containerValidButton"
                                         className='mt-2 d-flex justify-content-center'>
                                        <Button
                                            variant='danger'
                                            className='px-20'
                                        >Confirmer votre choix</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }

    </div>;

    function renderSwitch(key, value) {
        switch (key) {
            //texteDecline
            case 'titreDecline':
                return renderComponent(key, value, editortitreDecline, "var(--primary-color)", "btn btn-custom-grey", "#fff")
            case 'texteDecline':
                return renderComponent(key, value, editorRefDecline, "#f7f7f9", "btn btn-primary", "#403d38")
            case 'titre1':
                return renderComponent(key, value, editorTitre1, "#f7f7f9", "btn btn-primary", "#403d38")
            case 'titre2':
                return renderComponent(key, value, editorTitre2, "var(--primary-color)", "btn btn-custom-grey", "#fff")
            case 'sousTexteTitre2':
                return renderComponent(key, value, editorSousTexteTitre2, "var(--primary-color)", "btn btn-custom-grey", "#fff")
            case 'champs1':
                return renderComponent(key, value, editorRef, "var(--primary-color)", "btn btn-custom-grey", "#fff")
            case 'champs2':
                return renderComponent(key, value, editorRef2, "#f7f7f9", "btn btn-primary", "#403d38")
            case 'champs3':
                return renderComponent(key, value, editorRef3, "#fff", "btn btn-primary", "#403d38")
            case 'champs4':
                return renderComponent(key, value, editorRef4, "#f7f7f9", "btn btn-primary", "#403d38")
        }
    }

    function renderComponent(key, value, editorRef, backgroundColor, btnClass, color) {
        if (value[key].state.edit) {
            return <>{key === "champs1" && <Alert variant="warning" className="">
                Ce champs ne sera pas visible dans la page d'inscription libre
            </Alert>}
                {key === "sousTexteTitre2" && <Alert variant="warning" className="">
                    La civilité, le nom et le prénom resteront présents par défaut.
                </Alert>}
                <div className="d-flex align-items-center justify-content-between position-relative">
                    <div className={"bg-gray-100"} style={{
                        color: "#716d66",
                        fontWeight: "500",
                        borderRadius: "0.95rem",
                        minHeight: "50px",
                        padding: "10px",
                        width: "100%"
                    }}>
                        <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={champsPerso[key].html}
                            // inline={true}
                            init={{
                                skin: false,
                                // height: 500,
                                content_style: '.mce-content-body { min-height: 50px; }',
                                selector: '#editableDiv',
                                language: 'fr_FR',
                                language_url: '/lang/tinymce/fr_FR/langs/fr_FR.js',
                                menubar: false,
                                plugins: ["link autolink noneditable"],
                                default_link_target: '_blank',
                                toolbar: 'undo redo | fontsizeselect | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help | link unlink ',
                                setup: function (editor) {
                                    var toggleState = false;

                                    editor.ui.registry.addIcon('link', iconLienPj);

                                    editor.on('init', function (e) {
                                        editor.windowManager.oldOpen = editor.windowManager.open;
                                        editor.windowManager.open = function (t, r) {

                                            var modal = this.oldOpen.apply(this, [t, r]);  // call original

                                            linkUrlTinyMce(t, isValidHttpUrl, 2002, uploadFilePresentation)

                                            return modal;
                                        }
                                    });

                                    //Todo test pour rendre non supprimable Civilité Prénom Nom
                                    //
                                    // editor.on('keydown', function (event) {
                                    //     // console.log(editor?.selection?.getRng()?.commonAncestorContainer?.children[0].className)
                                    //     // console.log(editor?.selection?.getRng()?.commonAncestorContainer)
                                    //     console.log(editor?.selection?.getRng())
                                    //     console.log(editor?.selection?.getRng()?.startContainer)
                                    //     console.log(editor?.selection?.getRng()?.startContainer?.firstChild)
                                    //     console.log(editor?.selection?.getRng()?.endContainer?.firstChild)
                                    //     if (event.keyCode === 8 || event.keyCode === 46)  {
                                    //         try {
                                    //             var elem = editor.selection.getNode().parentNode;
                                    //             if (elem.classList.contains("panel-body") || elem.classList.contains("panel-heading")) {
                                    //                 if (elem.textContent.length == 0) {
                                    //                     event.preventDefault();
                                    //                     return false;
                                    //                 }
                                    //             }
                                    //         } catch (e) {}
                                    //     }
                                    // });
                                },

                            }}
                        />
                    </div>
                    <Button
                        variant={"default"}
                        style={{
                            zIndex: 10,
                            fontSize: 17,
                            top: 11,
                            right: 10,
                            color: "#222f3e",
                            // backgroundColor: "inherit",
                            fontWeight: 100,
                            borderLeft: "1px solid #ccc",
                            // borderRadius: 0
                        }}

                        onClick={() => {
                            if (key === "sousTexteTitre2") {
                                editorRef.current.setContent(editorRef.current.getContent().replace("<p>", "<p>Civilité Prénom NOM, "))
                            }
                            if (editorRef.current.getContent()) {
                                setChampsPerso({
                                    ...champsPerso,
                                    [key]: {
                                        html: editorRef.current.getContent(),
                                        state: {
                                            disable: false,
                                            edit: false,
                                            active: true
                                        }
                                    }
                                })
                            } else {
                                setChampsPerso({
                                    ...champsPerso,
                                    [key]: {
                                        html: editorRef.current.getContent(),
                                        state: {
                                            disable: true,
                                            edit: false,
                                            active: false
                                        }
                                    }
                                })
                            }
                        }}
                        className="position-absolute btn-tinymce p-2"
                    >
                        Fermer
                    </Button>
                </div>
            </>
        } else if (value[key].state.disable) {
            return <div style={{
                height: 50,
                width: "100%",
                backgroundColor: backgroundColor,
                borderRadius: 10,
                display: "flex",
                border: "dashed 1px #b5b0a1",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Button
                    variant={"default"}
                    className={btnClass}
                    onClick={() => {
                        setChampsPerso({
                            ...champsPerso,
                            [key]: {
                                ...champsPerso[key],
                                state: {
                                    disable: false,
                                    edit: true,
                                    active: false
                                }
                            }
                        })
                    }}
                >
                    Ajouter du texte
                </Button>
            </div>
        } else {
            return <>
                <div className={`position-relative ${key != "champs3" && "p-10"} pb-5`}>
                    <span style={{
                        color: color
                    }} dangerouslySetInnerHTML={{
                        __html: champsPerso[key].html
                    }}></span>
                    <Button
                        onClick={() => {
                            setChampsPerso({
                                ...champsPerso,
                                [key]: {
                                    ...champsPerso[key],
                                    html: null,
                                    state: {
                                        disable: true,
                                        edit: false,
                                        active: false
                                    }
                                }
                            })
                        }}
                        style={{right: 40, top: 0, width: 35, height: 35}}
                        className="fa fa-trash rounded-circle position-absolute p-0"
                        variant={"danger"}
                    >

                    </Button>
                    <Button
                        onClick={() => {
                            setChampsPerso({
                                ...champsPerso,
                                [key]: {
                                    ...champsPerso[key],
                                    state: {
                                        disable: false,
                                        edit: true,
                                        active: false
                                    }
                                }
                            })
                        }}
                        style={{right: 0, top: 0, width: 35, height: 35}}
                        className="fa fa-pen rounded-circle position-absolute p-0"
                        variant={"primary"}
                    >

                    </Button>
                </div>
            </>
        }
    }
})

export default PersonnaliserPageReponseDestinataireStep