import {Card, Button, Spinner, Alert} from "react-bootstrap";
import React, {useMemo, useRef, useState} from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import "flatpickr/dist/plugins/monthSelect/style.css"
import DetailReversionModal from "./DetailReversionModal";
import facturationProxy from "../../../proxies/facturation";
import useGetExportReversion from "../../../requests/facturation/reversion/useGetExportReversion";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import useGetIdsInvitationsReversion from "../../../requests/facturation/reversion/useGetIdsInvitationsReversion";
import FiltreParInvitationModal from "../../../views/FacturationView/Reversion/FiltreParInvitationModal";


function ReversionView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const urlExportReversion = useGetExportReversion({
        onSuccess: (data) => {
            window.open(data.url, '_blank');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const [idsInvitation, setIdsInvitation] = useState(null);
    const [isInclusif, setIsInclusif] = useState(true);
    const tabFilter =  useMemo(() => {
        if (idsInvitation){
            return {
                'tabIdsInvitation': idsInvitation,
                'isInclusif' : isInclusif
            };
        }else {
            return {}
        }
    }, [idsInvitation]);

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => {
                return <>
                    <span
                    style={{fill: "#211f1c"}}
                    title="Voir les détails de la réversion"
                    onClick={() => {
                        facturationProxy.showDetailReversion(row?.original);
                    }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><i className="fa fa-2x fa-eye"></i></span>
                    <span
                        style={{fill: "#211f1c"}}
                        title="Télécharger les informations concernant la réversion"
                        onClick={() => {
                            urlExportReversion.mutate(row?.original.id)

                        }} className='svg-icon svg-icon-2x m-0 ms-2 cursor-pointer'><i className="fa fa-2x fa-download"></i></span>
                </>
            },
            minWidth: 100,
            maxWidth: 100,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 80,
            maxWidth: 80,
        },
        {
            Header: 'Id des invitations',
            id:"idsInvitations",
            minWidth: 80,
            maxWidth: 80,
            Cell: ({row}) => {
                let dataParticipants = useGetIdsInvitationsReversion(row?.original?.id)
                if (dataParticipants?.isLoading){
                    return <div className='text-center'><Spinner animation='border'/></div>
                } else if(dataParticipants?.isError) {
                    return <Alert variant='danger'>{dataParticipants?.error?.message}</Alert>
                }else {
                    return dataParticipants?.data?.idsInvitation
                }
            },
        },
        {
            Header: 'Montant',
            accessor: 'montant',
            Cell: ({ row }) => {
                return  row?.original?.montant.toString().replace(".", ",")+"€"
            },
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Date création',
            accessor: 'dateCreation',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Date réception',
            accessor: 'dateArriveeSurCompte',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Méthode',
            accessor: 'methode',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'idsInvitation',
            accessor: 'tabIdsInvitation',
            hidden: true
        },

    ], []);

    return <>
        <Card>
            <Card.Body>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <div className="d-flex align-items-center">
                        <Button
                            style={{
                                height: "fit-content"
                            }}
                            variant="secondary"
                            onClick={() => {
                                facturationProxy.showFiltreParInvitation = true
                            }}
                        >Filtrer par invitation {idsInvitation && "("+idsInvitation.length+")" }
                            {idsInvitation && <>
                                <span onClick={(event) => {
                                    event.stopPropagation()
                                    setIdsInvitation(null)
                                }} title={"supprimer le filtre sur les invitations"} className="ms-3 p-2 py-1 ps-3" style={{
                                    height: "fit-content",
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: 30
                                }}>
                                    <i style={{
                                        color:"white"
                                    }} className="fa cursor-pointer fa-2x fa-times"></i>
                                </span></>}
                        </Button>
                    </div>
                    <Button
                        onClick={() => {
                            window.open("https://dashboard.stripe.com/reports/reconciliation", "_blank")
                        }}
                    ><i className="fa fa-external-link-alt"></i>Rapprochements des virements stripe</Button>
                </div>

                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url='reversionPaiement'
                    keys={['reversionPaiement']}
                    columns={columns}
                    search={searchFilter}
                    tabFilter={tabFilter}
                />
                {/*<div className='d-flex mt-3'>*/}
                {/*    <Button*/}
                {/*        variant='secondary'*/}
                {/*        className='ms-auto'*/}
                {/*        onClick={() => {*/}
                {/*            // facturation.showExportFacturation(urlExport?.data)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <FontAwesomeIcon icon={faFileDownload} className='me-2' />*/}
                {/*        Exporter*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Card.Body>
        </Card>
        <DetailReversionModal/>
        <FiltreParInvitationModal setIdsInvitation={setIdsInvitation} setIsInclusif={setIsInclusif} idsInvitation={idsInvitation}/>
    </>;
}

export default ReversionView;