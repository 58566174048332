import facturationProxy from "../../../proxies/facturation";
import {Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import {useSnapshot} from "valtio";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {useMetaCheckboxes} from "../../../components/MetaTable/useMetaCheckboxes";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";

export default function FiltreParInvitationModal({setIdsInvitation, idsInvitation, setIsInclusif}){
    const snapFacturation = useSnapshot(facturationProxy)
    const invitationsCheckboxes = useMetaCheckboxes();
    const invitationColumns = useMemo(() => getTabColsInvitations(), [invitationsCheckboxes]);
    const { register, watch, setValue } = useForm();
    const searchInvitation = watch('searchInvitation');
    const [debouncedSearchInvitation] = useDebounce(searchInvitation, 300);
    const colSearch =  {
        'seulementAvecReversion': true
    }

    useEffect(() => {
        setIsInclusif(!invitationsCheckboxes?.isSelectingAll)
    }, [invitationsCheckboxes?.isSelectingAll])

    useEffect(() => {
        if (idsInvitation == null){
            invitationsCheckboxes.reset();
        }
    }, [idsInvitation]);

    return <Modal
        show={snapFacturation.showFiltreParInvitation}
        onHide={() => {
            facturationProxy.showFiltreParInvitation = false;
        }}
        size={"xl"}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Vous filtrez les réversions par invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
            <div style={{
                flex: 1,
                justifyContent: "center",
            }} className="mb-5">
                <div style={{
                    width: "80%",
                    margin: "auto",
                    marginTop: 30
                }}>
                    <h2 className="text-center">Sélectionnez une ou plusieurs invitations</h2>
                    <div className="d-flex justify-content-between align-items-center">
                        <SearchBar
                            solid
                            {...register('searchInvitation')}
                            onClear={searchInvitation?.length > 0 ? () => {
                                setValue('searchInvitation', '');
                            } : null}
                        />
                    </div>
                    <MetaTable
                        className='mt-5'
                        height={300}
                        url='invitation'
                        keys={['invitations']}
                        tabFilter={colSearch}
                        columns={invitationColumns}
                        search={debouncedSearchInvitation}
                    />
                    <Button
                        className="mt-3 m-auto w-500px d-block"
                        onClick={() => {
                            setIdsInvitation(invitationsCheckboxes?.getIds());
                            facturationProxy.showFiltreParInvitation = false;
                        }}
                    >Filtrer les réversions</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>

    function getTabColsInvitations() {
        return [
            {
                Header: () => <div className='my-2'>
                    <Form.Check
                        className='form-check form-check-custom'
                        checked={invitationsCheckboxes.isSelectingAll}
                        onChange={() => invitationsCheckboxes.toggleSelectAll()}
                    />
                </div>,
                id: 'selector',
                Cell: ({row}) => <Form.Check
                    className='form-check form-check-custom'
                    checked={invitationsCheckboxes.isItemChecked(row.original.id)}
                    onChange={() => {
                        // const goToParticipant = document.getElementById("participant_"+row.original.participant.personne.id);
                        // goToParticipant.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                        invitationsCheckboxes.toggleItem(row.original.id)
                    }}
                />,
                width: 60,
                disableResizing: true,
                disableSearch: true,
                noWrap: true,
            },
            {
                Header: 'Id',
                accessor: 'id',
                minWidth: 70,
                maxWidth: 70,
            },
            {
                Header: 'Sujet',
                accessor: 'sujet',
                minWidth: 300,
                noWrap: true
            },
            {
                Header: "Date de l'événement",
                accessor: 'dateEvenementFormatee',
                minWidth: 300,
            },
            {
                Header: 'seulement reversion',
                accessor: 'seulementAvecReversion',
                hidden: true
            },
        ];
    }
}