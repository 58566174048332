import DetailReversionView from "./DetailReversionView";
import facturationProxy from "../../../proxies/facturation";
import {useSnapshot} from "valtio";
import {Modal} from "react-bootstrap";

function DetailReversionModal() {
    const facturationSnap = useSnapshot(facturationProxy);

    return <Modal
        show={facturationSnap.showDetailReversionModal}
        onHide={() => facturationProxy.showDetailReversionModal = false}
        size='xl'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Détails de la réversion - Date du virement le {facturationSnap?.reversion?.dateCreation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DetailReversionView/>
        </Modal.Body>
    </Modal>;
}

export default DetailReversionModal;