import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import invitationsProxy from "../../../../../proxies/invitations";
import PreviewFacturation
    from "./PreviewFacturationModal";
import useChangeInvitation from "../../../../../requests/communication/invitations/useChangeInvitation";
import Select2 from "../../../../../components/Select2/Select2";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";

const FacturationStep = forwardRef((_, ref) => {
    const {
        register, handleSubmit, reset, control, getValues, watch, setValue,
        formState: { errors },
    } = useForm({
        defaultValues: invitationsProxy.invitation,
    });
    const factureIsAuto = watch('sousLibelleArticleFactureIsAutomatique');
    const changeInvitation = useChangeInvitation();

    useImperativeHandle(ref, () =>
        ({
            save: async () => {
                let done = false;
                await handleSubmit(async (data) => {
                    try {
                        const result = await changeInvitation.mutateAsync(data);
                        invitationsProxy.invitation = result;
                        done = true;
                    } catch {
                        done = false;
                    }
                })();
                return done;
            },
        }));

    return <div className='mw-800px mx-auto'>
        <Form onSubmit={handleSubmit((data) => {
        })}>
            <h2 className='fw-bolder d-flex align-items-center text-dark mb-10'>
                Facturation
            </h2>
            <div
                className="btn-group mb-3" data-kt-buttons="true"
                data-kt-buttons-target="[data-kt-button]">
                <label
                    className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${factureIsAuto ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            setValue("sousLibelleArticleFactureIsAutomatique", true)
                        }}
                        className="btn-check" type="radio" name="method" value={1}/>
                    <span style={{fontSize: '10px'}}>Par défaut</span></label>
                <label
                    className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!factureIsAuto ? `active` : ``}`}
                    data-kt-button="true">
                    <input
                        onClick={(err) => {
                            // validInvitation.mutate(row.original.id)
                            setValue("sousLibelleArticleFactureIsAutomatique",false)
                        }}
                        className="btn-check" type="radio" name="method" value={2}/>
                    <span style={{fontSize: '10px'}}>Personnalisé</span></label>
            </div>
            {!factureIsAuto && <>
                <Form.Group>
                <Form.Label className='required'>Personnalisation du libelle</Form.Label>
                <Form.Control
                    maxLength={200}
                    as='textarea'
                    id="libelle"
                    rows={5}
                    style={{borderRadius: ".95rem .95rem 0 0"}}
                    {...register('valeurPersonnaliseeSousLibelleArticleFacture', { required: true })}
                />
                {errors.valeurPersonnaliseeSousLibelleArticleFacture && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Form.Group>
            </>}
            <PreviewFacturation valeurPersonalise={!factureIsAuto ? watch('valeurPersonnaliseeSousLibelleArticleFacture') : null }/>
        </Form>
    </div>
})

export default FacturationStep;