import React, {useState} from "react";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {emailPattern} from "../../../../../functions/patterns";
import CiviliteSelection from "../../../../personne/CiviliteSelection/CiviliteSelection";
import Select2 from "../../../../../components/Select2/Select2";

function AccompagnantAddForm({ setValueInitial, getValues, data, convertTabOptionsParticipation, setShowAddModal, setAccompagnantAdd, accompagnantAdd, dataInvitation = null, getTabPaiementsSurPlaceActif, getTabPaiementsCBEnAttenteActif }) {

    const { register, control, handleSubmit, setValue, unregister,
        formState: { errors } } = useForm();

    const [optionSelected, setOptionSelected] = useState(null);
    const [paieSaPart, setPaieSaPart] = useState(false);
    const [isTransfere, setIsTransfere] = useState(false);
    const [isPaiementCBSelected, setIsPaiementCBSelected] = useState(true);

    function onSave(data){
        data.tabAccompagnants = {
            idInvitation : null,
            idPersonneHote : null,
            optionParticipation : data.tabAccompagnants.optionParticipation,
            dateEnvoiInvitation : null,
            destinataire : {
                personne : {
                    civilite: data.tabAccompagnants.destinataire.personne.civilite,
                    prenom: data.tabAccompagnants.destinataire.personne.prenom,
                    nom: data.tabAccompagnants.destinataire.personne.nom,
                    email: data.tabAccompagnants.destinataire.personne.email,
                    indicatifTel1:"33",
                    indicatifTel2:"33",
                    id:null,
                },
            },
            tabPaiements: data?.tabAccompagnants?.tabPaiements,
            paieSaPart:paieSaPart,
            id : null,

        }
        if ((dataInvitation?.paiementEnLigneIsUtilise && !dataInvitation?.paiementSurPlaceIsAutorise)){
            data.tabAccompagnants.tabPaiements = getTabPaiementsCBEnAttenteActif();
        }else if((!dataInvitation?.paiementEnLigneIsUtilise)){
            data.tabAccompagnants.tabPaiements = getTabPaiementsSurPlaceActif();
        }else if (!data?.tabAccompagnants?.tabPaiements){
            data.tabAccompagnants.tabPaiements = getTabPaiementsCBEnAttenteActif();
        }
        let tabInitial = getValues("tabAccompagnants")
        let newTab = [
            ...tabInitial,
            ...Object.values(data)
        ]
        setValueInitial("tabAccompagnants", newTab)
        setAccompagnantAdd(accompagnantAdd+1)
        setShowAddModal(false)
    }

    return <div>
                <Row>
                    <Col className="d-flex justify-content-center mt-3" sm={12} >
                        <div className="btn-group" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                            <label
                                className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!paieSaPart ? `active` : ``}`}
                                data-kt-button="true">
                                <input
                                    onClick={(err) => {
                                        setIsTransfere(false)
                                        setPaieSaPart(false)
                                        setValue('tabAccompagnants.optionParticipation', optionSelected)
                                    }}
                                    className="btn-check" type="radio" name="method"/>
                                <span style={{fontSize: '10px'}}>Prendre en charge son inscription</span></label>
                            <label
                                className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success  ${paieSaPart ? `active` : ``}`}
                                data-kt-button="true">
                                <input
                                    onClick={(err) => {
                                        setIsTransfere(true)
                                        unregister("tabAccompagnants.optionParticipation")
                                        setPaieSaPart(true)
                                    }}
                                    className="btn-check" type="radio" name="method"/>
                                <span style={{fontSize: '10px'}}>Lui transférer l'invitation</span></label>
                        </div>
                    </Col>
                    <Form.Group className={'mb-3'}>
                        <Form.Label className='required'>Civilité</Form.Label>
                        <CiviliteSelection isSolid={false} control={control} name={`tabAccompagnants.destinataire.personne.civilite`} rules={{ required: true }} />
                        {errors.tabAccompagnants && errors.tabAccompagnants?.destinataire?.personne?.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Prenom</Form.Label>
                            <Form.Control maxLength={20} className='form-control' {...register(`tabAccompagnants.destinataire.personne.prenom`, {required : true})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants?.destinataire?.personne?.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control maxLength={50} className='form-control' {...register(`tabAccompagnants.destinataire.personne.nom`, {required : true})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants?.destinataire?.personne?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control maxLength={50} className='form-control' {...register(`tabAccompagnants.destinataire.personne.email`, {required : true, pattern: emailPattern()})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants?.destinataire?.personne?.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                        </FormGroup>
                    </Col>
                    {(!isTransfere && convertTabOptionsParticipation.length > 0) &&
                        <Col sm={6}>
                            <Form.Label>Option de participation</Form.Label>
                            <Select2
                                allowClear={true}
                                data={data}
                                {...register(`tabAccompagnants.optionParticipation`, {required: convertTabOptionsParticipation.length > 0})}
                                selected={optionSelected != null ? [optionSelected?.id?.toString()] : null}
                                onChange={(selected) => {
                                    selected = selected?.at(0);
                                    const res = convertTabOptionsParticipation.find(
                                        exp => exp.id.toString() === selected
                                    );
                                    setOptionSelected(res)
                                    setValue('tabAccompagnants.optionParticipation', res)
                                }}
                                minimumResultsForSearch={3}
                                placeholder='Selectionner une option de participation'

                            />
                            {errors.tabAccompagnants && errors.tabAccompagnants.optionParticipation &&
                                <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </Col>
                    }
                    {((dataInvitation?.paiementEnLigneIsUtilise && dataInvitation?.paiementSurPlaceIsAutorise && !isTransfere)) &&
                        <div className="d-flex flex-wrap justify-content-center mt-2">
                            <h4 className="w-100 text-center">Mode de règlement</h4>
                            <div className="btn-group" data-kt-buttons="true"
                                 data-kt-buttons-target="[data-kt-button]">
                                <label
                                    className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!isPaiementCBSelected ? `active` : ``}`}
                                    data-kt-button="true">
                                    <input
                                        onClick={() => {
                                            setValue('tabAccompagnants.tabPaiements', getTabPaiementsSurPlaceActif())
                                            setIsPaiementCBSelected(false);
                                        }}
                                        className="btn-check" type="radio" value={1}/>
                                    <span style={{fontSize: '10px'}}>{"Régler sur place"}</span></label>
                                <label
                                    className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${isPaiementCBSelected ? `active` : ``}`}
                                    data-kt-button="true">
                                    <input
                                        onClick={() => {
                                            setValue('tabAccompagnants.tabPaiements', getTabPaiementsCBEnAttenteActif())
                                            setIsPaiementCBSelected(true)
                                        }}
                                        className="btn-check" type="radio" value={2}/>
                                    <span style={{fontSize: '10px'}}>{"Régler maintenant par carte bancaire"}</span></label>
                            </div>
                        </div>
                    }
                </Row>
                    <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
                        <Button
                            variant='secondary'
                            className='px-20'
                            onClick={handleSubmit(onSave)}
                        >Ajouter l'accompagnant</Button>
                    </div>
    </div>;
}

export default AccompagnantAddForm;