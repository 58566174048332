import {Button, Modal, Form} from "react-bootstrap";
import {useSnapshot} from "valtio";
import facturationProxy from "../../../proxies/facturation";
import {useMetaCheckboxes} from "../../../components/MetaTable/useMetaCheckboxes";
import React, {useEffect, useMemo, useState} from "react";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import useGetExportFacturation from "../../../requests/facturation/useGetExportFacturation";
import LoadingView from "../../../views/LoadingView/LoadingView";


function ExportFacturationModal() {
    const snapFacturation = useSnapshot(facturationProxy)
    const invitationsCheckboxes = useMetaCheckboxes();
    const invitationColumns = useMemo(() => getTabColsInvitations(), [invitationsCheckboxes]);
    const { register, watch, setValue } = useForm();
    const searchInvitation = watch('searchInvitation');
    const [debouncedSearchInvitation] = useDebounce(searchInvitation, 300);
    const [idsInvitation, setIdsInvitation] = useState(null);
    const urlExport = useGetExportFacturation(idsInvitation, !invitationsCheckboxes?.isSelectingAll);
    const [exportIsVisible, setExportIsVisible] = useState(false);
    const colSearch =  {
        'seulementAvecFacture': true
    }

    useEffect(() => {
        setIdsInvitation(invitationsCheckboxes?.getIds().toString());
    }, [invitationsCheckboxes?.getIds()]);


    if (urlExport?.isLoading && exportIsVisible){
        return <LoadingView/>

    }
    return <Modal
        show={snapFacturation.showExportFacturationModal}
        onHide={() => {
            facturationProxy.showExportFacturationModal = false
            setExportIsVisible(false);
            invitationsCheckboxes.reset();

        }}
        fullscreen={exportIsVisible}
        size={"xl"}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Vous exportez la liste des factures</Modal.Title>
            {exportIsVisible && <p style={{width : "fit-content"}} className="alert alert-info m-auto mt-0 mb-0 p-2">Aperçu des 2000 premières lignes. Afin de visionner toutes les lignes, télécharger l'export via le nuage en bas à droite.</p>}
        </Modal.Header>


        <Modal.Body className="p-0">
            {!exportIsVisible ?
                <div style={{
                    flex: 1,
                    justifyContent: "center",
                }} className="mb-5">
                    <div style={{
                        width: "80%",
                        margin: "auto",
                        marginTop: 30
                    }}>
                        <h2 className="text-center">Vous pouvez exporter les factures d'un ou plusieurs évènements en sélectionnant les invitations (facultatif)</h2>
                        <div className="d-flex justify-content-between align-items-center">
                            <SearchBar
                                solid
                                {...register('searchInvitation')}
                                onClear={searchInvitation?.length > 0 ? () => {
                                    setValue('searchInvitation', '');
                                } : null}
                            />
                        </div>
                        <MetaTable
                            className='mt-5'
                            height={300}
                            url='invitation'
                            keys={['invitations']}
                            columns={invitationColumns}
                            tabFilter={colSearch}
                            search={debouncedSearchInvitation}
                        />
                        <Button
                            className="mt-3 m-auto w-500px d-block"
                            onClick={() => setExportIsVisible(true)}
                        >Exporter {invitationsCheckboxes.getIds().length > 0 ? "la liste des factures des événements choisis" : "toute la liste des factures"}</Button>
                    </div>
                </div> :
                <div style={{
                    height: "100vh"
                }}>
                    <Button
                        className="ms-5 mt-3"
                        onClick={() => setExportIsVisible(false)}
                    >Retourner au choix d'invitations</Button>
                    <iframe width={"100%"} height={"100%"} src={urlExport?.data} ></iframe>
                </div>
            }

        </Modal.Body>
    </Modal>;

    function getTabColsInvitations() {
        return [
            {
                Header: () => <div className='my-2'>
                    <Form.Check
                        className='form-check form-check-custom'
                        checked={invitationsCheckboxes.isSelectingAll}
                        onChange={() => invitationsCheckboxes.toggleSelectAll()}
                    />
                </div>,
                id: 'selector',
                Cell: ({row}) => <Form.Check
                    className='form-check form-check-custom'
                    checked={invitationsCheckboxes.isItemChecked(row.original.id)}
                    onChange={() => {
                        // const goToParticipant = document.getElementById("participant_"+row.original.participant.personne.id);
                        // goToParticipant.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
                        invitationsCheckboxes.toggleItem(row.original.id)
                    }}
                />,
                width: 60,
                disableResizing: true,
                disableSearch: true,
                noWrap: true,
            },
            {
                Header: 'Id',
                accessor: 'id',
                minWidth: 70,
                maxWidth: 70,
            },
            {
                Header: 'Sujet',
                accessor: 'sujet',
                minWidth: 300,
                noWrap: true
            },
            {
                Header: "Date de l'événement",
                accessor: 'dateEvenementFormatee',
                minWidth: 300,
            },
            {
                Header: 'seulement avec facture',
                accessor: 'seulementAvecFacture',
                hidden: true
            },
        ];
    }

}

export default ExportFacturationModal;