import { useMutation } from "react-query";
import baseApi from "../../../services/apiPublic";
import api from "../../../services/api";

export default function useChangeCorrespondant(props, isCheckedResponsable = null) {
    if (isCheckedResponsable){
        return useMutation(changeCorrespondantConnecte, props);
    }else {
        return useMutation(changeCorrespondant, props);
    }
}

export async function changeCorrespondant(correspondant) {
    const id = correspondant.id;

    if (id != null)
        return baseApi.post(`correspondantInvitation/${id}/repondre`, { json: correspondant }).json();

}

export async function changeCorrespondantConnecte(correspondant) {
    const id = correspondant.id;

    if (id != null)
        return api.post(`correspondantInvitation/${id}/repondre`, { json: correspondant }).json();

}
