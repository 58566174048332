import {useFieldArray} from "react-hook-form";
import {useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, InputGroup, Row, Stack} from "react-bootstrap";
import $ from "jquery";
import TauxTvaSelection from "../../../../../../views/communication/TauxTvaSelection/TauxTvaSelection";

export default function ArticlesOptionsForm({ removeOption, control, namePart1, namePart2, indexOptionEdited, trigger, setShowEditOptionParticiparion, idOption, name, register, boolPaiementEnLigneIsUtilise, getValues, unregister, errors, name2 }){

    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });
    const [montantTotalOption, setMontantTotalOption] = useState(0);
    const [montantUpdate, setMontantUpdate] = useState(0);

    useEffect(() => {
        if (fields.length < 1)
            append({
                designation: '',
                montant: '0',
                idOptionParticipationInvitation:idOption,
                tauxTva:null,
                id: null,
            });
    }, [fields, append]);

    useEffect(() => {
        if (getValues(`${name}`)){
            let montantCalc = 0;
            getValues(`${name}`)?.forEach(item => {
                montantCalc = montantCalc + Number(item?.montant);
            })
            setMontantTotalOption(montantCalc);
        }
    }, [fields, montantUpdate]);


    return <div>
        <h2>Option de participation</h2>
        <Col className="mt-5" md={12}>
            <FormGroup as={Col}>
                <Form.Label className="required">Désignation</Form.Label>
                <Form.Control maxLength={100} className='form-control-solid' {...register(`${namePart1}.${indexOptionEdited}.designation`, {required: true})} />
                {errors?.[namePart1]?.[indexOptionEdited]?.["designation"] && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </FormGroup>
        </Col>
        <h2 className="mt-5">Articles</h2>
        {fields.map((item, index) => (
            <Stack key={item.id} className={index === 0 ? 'mt-5' : ''}>
                <Row>
                    <Col md={4}>
                        <FormGroup as={Col}>
                            <Form.Label className="required">Désignation</Form.Label>
                            <Form.Control maxLength={100} className='form-control-solid' {...register(`${name}.${index}.designation`, {required: true})} />
                            {errors?.[namePart1]?.[indexOptionEdited]?.[namePart2]?.[index]?.["designation"] && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col md={3} className='mt-5 mt-sm-0'>
                        <FormGroup as={Col}>
                            <Form.Label >Montant</Form.Label>
                            <InputGroup onChange={(e) => {
                                setMontantUpdate(montantUpdate+1)
                                if (e.target.value && e.target.value != 0 && ! getValues(`${name}.${index}.designation`)) {
                                    unregister(`${name}.${index}.designation`)
                                    register(`${name}.${index}.designation`, { required: true })
                                }
                                else if (! getValues(`${name}.${index}.designation`) && e.target.value == 0){
                                    unregister(`${name}.${index}.designation`)
                                    register(`${name}.${index}.designation`)
                                }
                                if (boolPaiementEnLigneIsUtilise){
                                    $("#montantRestant"+index).text(parseFloat(getValues(`${name}.${index}.montant`)*0.981 - 0.5).toFixed(2) + "€ restant")
                                }else {
                                    $("#montantRestant"+index).text("")
                                }
                            }} className='input-group-solid'>
                                <Form.Control type="number" className='form-control-solid' {...register(`${name}.${index}.montant`, {min: montantTotalOption == 0 ? 0 : 0.5})} />
                                <InputGroup.Text className='text-muted fw-bold fs-5'>
                                    € TTC
                                </InputGroup.Text>
                                <InputGroup.Text id={"montantRestant"+index} className='text-muted fw-bold fs-5'>

                                </InputGroup.Text>

                            </InputGroup>
                            {errors?.[namePart1]?.[indexOptionEdited]?.[namePart2]?.[index]?.["montant"] && <Form.Text className='text-danger'>Minimum 0.50 €</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col md={5} className='mt-5 mt-sm-0'>
                        <Form.Group>
                            <Form.Label className={!!getValues(`${name}.${index}.designation`) ? "required" : ""}>Taux de TVA</Form.Label>
                            <TauxTvaSelection control={control}  name={`${name}.${index}.tauxTva`} rules={{ required: !!getValues(`${name}.${index}.designation`) }} />
                        </Form.Group>
                        {errors[name] && errors[name][index] && errors[name][index]?.tauxTva && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                    {index === fields.length - 1 && <Button
                        variant='link'
                        className='btn-color-info btn-active-color-primary'
                        onClick={() => append({
                            designation: '',
                            montant: '0',
                            tauxTva:null,
                            idOptionParticipationInvitation:idOption,
                            id: null,
                        })}
                    >Ajouter une option</Button>}
                    {fields.length > 1 && <Button
                        variant='link'
                        className='btn-color-danger btn-active-color-primary ms-auto'
                        onClick={() => remove(index)}
                    >Enlever l'option</Button>}
                </div>
            </Stack>
        ))}
        <Button onClick={async () => {
            if (await trigger(name) && await trigger(name2)) {
                setShowEditOptionParticiparion(false)
            }
        }}>
            Valider
        </Button>
        {!idOption &&
            <Button className="ms-3" onClick={async () => {
                removeOption()
                setShowEditOptionParticiparion(false)
            }}>
                Annuler
            </Button>
        }
    </div>;

}